.pauseMenuContainer {
  background: #000000;
  height: 100%;
  width: 100%;
  // position: fixed;
  top: 0;
  opacity: 0.85;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pauseMenuContents {
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.pageNumberContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.authorIconsContainer {
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.seeMoreContainer {
  color: white;
  text-align: center;
  // text-decoration: underline;
}

.pauseMenuAuthorLink {
  color: red;
  text-decoration: none;
}

#subButtonContainer {
  text-align: center;
}

#subButton {
  color: white;
  background-color: red;
  border: none;
}

.pauseMenuIconsContainer {
  display: flex;
  justify-content: space-around;
  // align-items: center;
  font-size: xx-large;
}

.pauseMenuCircle {
  opacity: 2 !important;
  // color: red;
}

.pauseMenuIconsContainer span {
  height: 42.67px;
  width: 42.87px;
}
