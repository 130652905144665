.backToAccount {
  margin-left: 20px;
  /* border: 2px solid red; */
}

/* .titleInput {
    border: none!important;
    font-size: xx-large;
}
*/
.textEditor {
  margin: 0 10px 0 10px;
  padding-top: 10px;
}

/* .textEditor::placeholder {
    color: #949494!important;
}  */

.newPieceContainer {
  // border: 2px solid red;
}
