.aboutTitle {
  text-align: center;
  top: 0;
  font-size: 2em;
  font-weight: bold;
  margin-top: 30px;
}

.aboutContainer {
  // border: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutContent {
  // border: 2px solid blue;
  width: 95%;
}

// Unused
.navbar--hidden {
  margin-bottom: 0;
}
