#pauseMenuHeader {
  font-family: "Heebo", sans-serif;
  padding: 0;
  left: 0;
  right: 0;
  background: #fff;
  font-weight: 600;
  height: 50px;
  z-index: 1;
  // border: 2px solid red;
}

.pauseMenuHeaderContentContainer {
  display: flex;
  height: 50px;
  color: black;
  align-items: center;
  justify-content: space-between;
}

.pauseMenuHeaderRightDiv {
  display: flex;
  justify-content: space-around;
  width: 215px;
  align-items: center;
  // border: 2px solid red;
}

.navbar {
  width: 100%;
  padding: 10px;
  // position: fixed;
  top: 0;
  transition: top 0.6s;
  /* background-color: red; */
  /* border: 2px solid red; */
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.backToAccount {
  margin-left: 20px;
  /* border: 2px solid red; */
}

// Most of these are unused

.savePiece {
  text-decoration: none;
  font-size: 24px;
  color: black;
  vertical-align: middle;
}

.ellipsisMenuIcon {
  padding-right: 20px;
  font-size: 28px;
}

.pageCount {
  margin-bottom: 0;
  font-size: 20px;
}

.arrowLeftIcon {
  color: black;
  font-size: 28px;
  font-weight: 100;
}
