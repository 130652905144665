/* @media only screen and (max-width: 376px) {
   #footer {
       color: red; 
       bottom: 0px;
   }
   #icons {
       display: flex;
       color: red; 
       font-size: 30px;
   }
  } */

#footer {
  // border-top: 2px solid black;
  // box-shadow: 5px 10px 20px;
  box-shadow: 0 18px 22px 8px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  z-index: 2;
  height: 64px;
  box-sizing: border-box;
}

// #icons {
//   display: flex;
//   justify-content: space-between;
// }

.icon {
  color: #585757;
  font-size: 28px;
  padding-bottom: 10px;
  justify-content: space-between;
  /* width: 92%; */
  /* border-top: 2px solid black; */
}

.icon:hover {
  color: red;
}

/* .icon:active {
    color: red;
} */

.icon:focus {
  color: red;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.footerIcons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  // padding-left: 15px;
  // padding-right: 15px;
}

.iconTitle {
  // font-size: 18px;
}

/* #homeIcon {
    padding-left: 15px;
}

#accountIcon {
    padding-right: 15px;
} */
