.card {
  padding-left: 30px;
}

// TODO: MOVE CSS INTO THEIR COMPONENTS
// Most of these are unused now.
.cardPostDate {
  color: gray;
  font-weight: normal;
  /* font-size: 38px; */
}

.cardPostDate,
.cardSummary {
  margin-bottom: 0;
}

.cardPostDate {
  font-size: large;
}

.cardSummary {
  font-size: large;
}

//TODO: DELETE This
// hr {
//   // margin-top: 3rem;
//   // margin-bottom: 3.5rem;
//   width: 90%;
//   text-align: center;
// }
