// .textEdiorPlaceholder {
//     font-size: large;
//     margin-left: 10px;
// }

.titleInput {
  border: none !important;
  font-size: xx-large;
}

input.titleInput {
  // padding: 0;
}

#editor {
  border: none;
  resize: none;
  font-size: large;
}

// These are not used.
.public-DraftEditorPlaceholder-inner,
.DraftEditor-editorContainer {
  padding-left: 15px;
  padding-right: 15px;
}

.public-DraftEditorPlaceholder-inner {
  color: #757575;
}

// .textEditor::placeholder {
//     color: #949494!important;
// }
