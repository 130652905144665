.readersModeTitle {
  font-family: "Segoe UI";
  font-weight: bold;
  color: #403f3f;
  font-size: 32pt;
}

.readersModeAuthor {
  font-family: "Segoe UI";
  font-size: 16pt;
  text-decoration: none;
  color: #fe4242;
}

.readersModeAuthor:focus {
  color: red;
}

.authorSettingsButton {
  height: 35px;
  font-family: "Segoe UI";
  font-size: 14px;
  // letter-spacing: 2.5px;
  font-weight: 800;
  color: #5d5858;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  margin-left: 15px;
  padding: 0 10px 0 10px;
  text-transform: none;
}

// .authorSettingsButton:hover {
//   background-color: red;
//   box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
//   color: #fff;
//   // transform: translateY(-7px);
// }

#authorSettingsIcon {
  color: red;
  font-size: 15px;
  margin-right: 5px;
}

#authorSettingsIcon:hover {
  color: #fff;
}