.loadMorePiecesContainer {
  margin-top: 10px;
  text-align: center;
}

.loadMorePiecesLink {
  text-decoration: none;
  color: gray;
}

.writersModeLink {
  text-decoration: none;
  color: black;
}
