.bgModal {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  /* display: none; */
  justify-content: center;
  align-items: center;
}

.modalContents {
  /* height: 300px; */
  /* width: 500px; */
  background-color: white;
  text-align: center;
  padding: 20px;
  position: relative;
  border-radius: 4px;
}

input {
  margin: 15px auto;
  display: block;
  width: 100%;
  border: 1px solid gray;
}

.closeDuplicateModal a {
  color: #333;
  cursor: pointer;
}

.closeDuplicateModal:hover {
  color: #666;
}

.deleteButton {
  background-color: red;
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 30px;
  text-transform: none;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  letter-spacing: 0.1rem;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #bbb;
  cursor: pointer;
  box-sizing: border-box;
}
