// Do not convert to module. This is our global stylesheet.

#root {
  padding-bottom: 64px;
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* overflow-y: hidden; */
  /* padding: 0;
  margin: 0; */
}

/* body > header {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#contentContainer {
  height: 100%;
  overflow: auto;
  padding: 0;
}

#routerContent {
  overflow-y: auto;
  font-family: "Heebo", sans-serif;
}

// Influences all headers across the app.
.sectionHeaders {
  margin: 10px 0 20px 10px;
  font-size: large;
  color: rgb(223, 80, 80);
  font-family: "Heebo", sans-serif;
}

/* FIXME: Rename to something more accurate (ie cardContainer) */
#footerContainer {
  // margin-top: 164px;
}

.readersModeLink {
  text-decoration: none;
  color: black;
}