.subscriptionsTab {
    overflow: auto;
    // border: 2px solid;
    height: calc(100vh - 64px);
}

.noSubText {
    color: #707070;
}

#emptySubButton {
    border: none;
}

.noSubContainer {
    display: flex;
    flex-direction: column;
    width: 85vw;
    height: 75vh;
    text-align: center;
    justify-content: space-around;
}
