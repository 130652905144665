/* @media only screen and (max-width: 376px) {
   #footer {
       color: red; 
       bottom: 0px;
   }
   #icons {
       display: flex;
       color: red; 
       font-size: 30px;
   }
  } */

#headerChips {
  /* border-top: 2px solid black; */
  /* color: red;  */
  /* position: absolute; */
  /* width: 100%; */
  font-family: "Heebo", sans-serif;
  left: 0;
  /* bottom: 0; */
  right: 0;
  background: #fff;
  // font-weight: 600;
  height: 148px;
  padding-top: 24px;
  box-shadow: none;
}

#header {
  font-family: "Heebo", sans-serif;
  left: 0;
  /* bottom: 0; */
  right: 0;
  background: #fff;
  // font-weight: 600;
  height: 96px;
  padding-top: 24px;
  box-shadow: none;
}

#icons {
  display: flex;
  color: #585757;
  font-size: 26px;
  justify-content: space-between;
  // align-items: center;
  /* border: 2px solid red; */
  /* width: 92%; */
  /* border-top: 2px solid black; */
}

#navBarBrand {
  padding-left: 24px;
  height: 48px;
  // width: 70%;
}

.headerSearchButton { //TODO: Remove?
  align-items: center;
  background: white;
  border-radius: 100%;
  box-shadow: 0px 3px 10px -2px;
  color: #666666;
  display: inline-flex;
  flex-direction: column;
  font-size: 30px;
  font-weight: bold;
  height: 45px;
  justify-content: center;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  width: 48px;
  // box-shadow: 5px 10px 20px;
}

.searchIcon {
  // width: 64px;
  // height: 64px;
  // margin-right: 32px;
}

// .navbar {
//   width: 100%;
//   padding: 16px;
//   position: fixed;
//   top: 0;
//   transition: top 0.6s;
//   /* background-color: red; */
//   /* border: 2px solid red; */
// }

// .navbarHidden {
//   top: -70px;
// }

.homeLogo {
  // text-decoration: none;
}

.pillsContainer {
  margin-top: 16px;
  margin-left: 24px;
  // margin-right: 24px;
  // // width: 100%;
  // display: flex;
  // justify-content: center;
}