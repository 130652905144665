.readersModeContent {
  box-sizing: border-box;
  font-family: "Segoe UI";
  font-size: 17pt;
  font-weight: normal;
  height: 100%;
  width: 100%;
  padding: 10px;
  color: #707070;
  overflow: auto;
}

.pageNumber {
  font-size: smaller;
  text-align: center;
}