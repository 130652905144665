.popover {
  position: fixed;
  float: right;
  margin-right: 13px;
  background-color: #434343;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 95px;
  right: 0;
}

.plusIcon {
  font-size: x-large;
  font-weight: lighter;
}

.popOverContent {
  background-color: white;
  border-radius: 10%;
  text-decoration: none;
  list-style-type: none;
  border: 0.5px solid #434343;
  padding-left: 15px;
  margin-right: 50px;
  width: 70%;
}

.newCollection {
  margin-top: 1rem;
}

.newPieceLink {
  text-decoration: none;
  color: black;
}
