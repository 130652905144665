.readersModeContainer {
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  // overflow-y: auto;
}

.DraftEditor-root .DraftEditor-editorContainer {
  padding: 0;
}
