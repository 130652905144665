.bgAuthorSettingsModal {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  /* display: none; */
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.authorSettingsModalContents {
  height: 30%;
  width: 75%;
  background-color: white;
  text-align: center;
  padding: 20px;
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

input {
  margin: 15px auto;
  display: block;
  width: 100%;
  border: 1px solid gray;
}

.closeAuthorSettingsModalLink a {
  color: #333;
  cursor: pointer;
  float: right;
}

.closeAuthorSettingsModalLink:hover {
  color: #666;
}

.authorSettingsModalButton {
  background-color: #474444;
  color: white;
  text-align: center;
  font-size: 10pt;
  font-weight: 600;
  // padding: 30px;
  text-transform: none;
  height: 40px;
  width: 140px;
  line-height: 38px;
  letter-spacing: 0.1rem;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #bbb;
  cursor: pointer;
  box-sizing: border-box;
}
