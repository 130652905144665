// TODO: DELETE this
// html,
// body {
//   height: 100%;
//   width: auto;
//   overflow: auto;
//   overflow-x: hidden;
//   overscroll-behavior-y: contain;
// }

.page {
  position: absolute;
  width: 100%;
  height: 100%;
  will-change: transform;
  overflow: auto;
  padding: 15px;
  box-sizing: border-box;
}

.page > div {
  /* touch-action: none; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.content {
  /* padding-bottom: 100px; */
}