.topDiv {
  align-items: center;
  background: #fff;
  border: 2px solid black;
  display: flex;
  font-size: medium;
  flex-direction: column;
  height: 115px;
  justify-content: center;
  margin-left: 10px;
  padding: 10px;
  width: 85px;
}

.bottomDiv {
  border: 2px solid black;
  height: 120px;
  position: absolute;
  top: 26px;
  width: 100px;
  z-index: -1;
}

.topAndBottomDivContainer {
  position: relative;
  margin-left: 5px;
}
