$accountDivBackground: white;

// That's a lot of IDs. Convert these to classes later.
// This was a weird holdover from userInfo.scss. Both of these should be refactored.
#userInfoContainer {
  background-color: $accountDivBackground;
  margin-top: 0;
  // border: 2px solid blue;
  padding-top: 105px;
  margin-top: -112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 235px;
  // color: white;
}

#userDetails {
  margin: 25px 0 20px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#pieces,
#subs,
#name {
  text-align: center;
  margin-bottom: 0;
}

#pieces,
#subs {
  margin-top: 10px;
  font-size: small;
}

#pieceCountLabel,
#subCountLabel {
  margin-bottom: 0;
}

#name {
  font-size: x-large;
}

#pieceCount,
#subCount {
  margin-bottom: 0;
}

#userBio {
  text-align: center;
}

#writersModeButton,
#deleteAccountButton,
#signOutButton {
  //margin-top: 72px;
  background-color: red;
  color: white;
}

#editOrSubButton,
#writersModeButton,
#deleteAccountButton,
#signOutButton {
  border: none;
}

#deleteAccountButton,
#signOutButton {
  margin-top: 25px;
}

.columns {
  //border: 2px solid blue;
}

.sectionTitles {
  font-size: x-large;
  padding-left: 10px;
}

#writersModeDiv {
  margin-top: 25px;
}

#writersModeButtonContainer,
#deleteAccountButtonContainer,
#signOutButtonContainer {
  text-align: center;
}

.collectionsContainer {
  display: flex;
  height: 150px;
  /* padding-left: 55px; */
  justify-content: space-around;
  overflow-x: auto;
}

#writersModeButtonContainer,
#collectionsContainer,
#darkModeDiv {
  margin-top: 50px;
}

#writersModeDiv,
#collectionsDiv,
#settingsDiv {
  margin-bottom: 50px;
}

#darkModeDiv {
  // border: 2px solid red;
}

#darkModeDiv p {
  font-size: large;
  padding-left: 15px;
  margin-top: 5px;
}

#darkModeDiv {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

/////////////
// sliders //
/////////////
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: red;
}

input:focus + .slider {
  box-shadow: 0 0 1px red;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.aboutLink {
  text-align: center;
  // margin-bottom: 5px;
  color: #ff0000;
  // border: 2px solid red;
}

.icon {
  color: #585757;
  font-size: 28px;
  padding-bottom: 10px;
  justify-content: space-between;
  /* width: 92%; */
  /* border-top: 2px solid black; */
}

.icon:hover {
  color: red;
}

/* .icon:active {
      color: red;
  } */

.icon:focus {
  color: red;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
