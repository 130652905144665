.savedTab {
    overflow: auto;
    // border: 2px solid;
    height: calc(100vh - 64px);
}

.noSavedText {
    color: #707070;
}

#emptySaveButton {
    border: none;
}

.noSavedontainer {
    display: flex;
    flex-direction: column;
    width: 85vw;
    height: 75vh;
    text-align: center;
    justify-content: space-around;
}
